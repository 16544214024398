import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';

import {dispatchInfoSnack, shouldDispatchInfoSnack, mapStateToProps} from "@dolnpm/redux-snack-messages";

const positioning = {
    vertical: 'bottom',
    horizontal: 'right',
}

const styles = theme => {
    return {
            root: {
                background: theme.palette.primary.main
            },
            notice: {
                background: theme.palette.primary.main
            },
            alert: {
                background: theme.palette.error.main
            },
            info: {
                background: theme.palette.primary.main
            },
            none: {
                background: theme.palette.primary.default
            }
        }

};


const mapDispatchToProps = dispatch => ({
});

class InfoSnackBar extends React.Component {
    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        if (reason === 'timeout') {
            dispatchInfoSnack()
        }

        // this.setState({ open: false });
    };

    componentWillReceiveProps(nextProps) {
        shouldDispatchInfoSnack(nextProps, this.props)
    }

    render() {
        const { classes, color, open, duration, snack } = this.props
        if (!snack) return false
        return (
            <Snackbar
                anchorOrigin={positioning}
                open={open}
                autoHideDuration={duration}
                message={<span id="message-id">{snack.message}</span>}
                onClose={this.handleClose}
                ContentProps={{
                    'aria-describedby': 'message-id',
                    classes: {
                        root: classes[snack.type]
                    }
                }}
            />
        )
    }
}

InfoSnackBar.propTypes = {

};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(InfoSnackBar));
