import { InitApp, attachState } from '@dolnpm/react-redux-init-app'
import {extractLink, extractLinks} from '@dolnpm/link-extractor'

import {fields} from './helpers/fields'
import 'config/shared/initializers'
import {opts} from 'config/shared/default-options'

import Auth from './Auth.jsx';

const signInLink = extractLink('#devise-sign-in')
const signUpLink = extractLink('#devise-sign-up')
const deviseLinks = extractLinks('#devise-links')

attachState({ fields, signInLink, signUpLink, deviseLinks })

InitApp(opts)(Auth, {})('auth')