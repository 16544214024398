import React from 'react';

import InfoSnackBar from 'components/info-snack-bar/InfoSnackBar.js.jsx'

class DefaultComponents extends React.Component {
    render() {
        return(
            <div>
                <InfoSnackBar />
            </div>
        )
    }

}

export default DefaultComponents