import defaultComponents from 'config/default-components/DefaultComponents.js.jsx'
import theme from 'config/theme/theme.js'
import { createLogger } from 'redux-logger'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'

export let opts = {
    defaultComponents,
    theme
}

if (true || process.env.NODE_ENV === 'development') {
    opts.logger = composeWithDevTools(createLogger())
}
