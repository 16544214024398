import {dataFromElement, errorsToFields} from '@dolnpm/data-fields-for'

export const fields = {
    email: {
        value: '',
        required: true,
    },
    password: {
        value: '',
        required: true,
    },
    password_confirmation: {
        value: '',
        required: true,
    }
}

const errors = dataFromElement('devise-errors')
errorsToFields(errors, fields)